import React, { useState } from 'react';
import "./style.scss";

const ModalPrivacy = ({ content }) => {
  const [isModalOpen, setModalOpen] = useState(true);
  const [isPrivacyAccepted, setPrivacyAccepted] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleCheckboxChange = () => {
    setCheckboxChecked(!isCheckboxChecked);
    setShowError(false);
  };

  const handleAcceptPrivacy = () => {
    if (!isCheckboxChecked) {
      setShowError(true);
    } else {
      setPrivacyAccepted(true);
      setModalOpen(false);
    }
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Accettazione della Privacy</h2>
            <p>
                Per poter fruire questo contenuto, è necessario leggere ed accettare la nostra politica sulla privacy per continuare.
            </p>
            <label>
              <input
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
                required
              />
              Dichiaro di aver letto ed accettato la <a href="https://it-pampanorama-dev.s3.eu-west-3.amazonaws.com/Whistleblowing/Informativa+privacy+segnalante.docx" target='_blank' className='link'>normativa sulla privacy</a>
            </label>
            {showError && (
              <p className="error">Seleziona la checkbox per continuare</p>
            )}
            <button onClick={handleAcceptPrivacy} className='btn'>
              Accetta
            </button>
          </div>
        </div>
      )}
      <div className={`content ${isPrivacyAccepted ? '' : 'blurred'}`}>
        {content}
      </div>
    </div>
  );
};

export default ModalPrivacy;
